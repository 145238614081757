<script setup>
const isVisible = ref(false)
const isOpen = ref(false)
const windowWidth = ref(0)
let observer

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth
  isOpen.value = windowWidth.value > 1024
}

const toggleOpen = () => {
  isOpen.value = !isOpen.value
}

// Crea nuovo IntersectionObserver che aggiorna isVisible
// ogni volta che l'intersezione tra l'elemento e il viewport cambia
const createObserver = (element) => {
  return new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isVisible.value = !entry.isIntersecting
    })
  })
}

// Seleziona l'elemento, disconnette l'observer precedente se esiste,
// crea un nuovo observer, per evitare più observer attivi in contemporanea
const observeElement = () => {
  const element = document.querySelector('.visibility-fixed-block')
  if (!element) {
    isVisible.value = true
    return
  }

  observer?.disconnect()
  observer = createObserver(element)
  observer.observe(element)
}

// Al mounted, se siamo sul lato client, iniziama l'observer
onMounted(() => {
  if (process.client) {
    nextTick(observeElement)
  }
  updateWindowWidth()
  window.addEventListener('resize', updateWindowWidth)
})

// Disconnettiamo l'observer prima dell'unmount del componente per evitare errori
// o che continui a lavorare in background
onBeforeUnmount(() => {
  observer?.disconnect()
  window.removeEventListener('resize', updateWindowWidth)
})

// Route corrente
const route = useRoute()

// Ogni volta che la route cambia, se siamo sul lato client, iniziamo l'observer
watchEffect(
  () => {
    if (process.client) {
      const path = route.path
      nextTick(observeElement)
    }
  },
  { flush: 'post' },
)
</script>

<template>
  <div
    v-if="isVisible"
    class="fixed-block"
    :class="{ open: isOpen }"
    @click="toggleOpen"
  >
    <p class="top-right-icon text-4" alt="Icon description">
      {{ isOpen ? '-' : '+' }}
    </p>
    <div v-if="isOpen">
      <p class="text-4 intro">
        Sai che puoi essere sanzionato fino a 8.000 € per un documento non
        conservato a norma di legge?
      </p>
      <MainButton
        text="Scopri se sei conforme"
        url="/questionario-conformita"
        :internal-link="true"
        :is-black="false"
      />
    </div>
    <div v-if="!isOpen">
      <p class="intro closed">SEI CONFORME AgID?</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixedBlock',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
